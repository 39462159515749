import { FLOW_KEY, setupTrackingEventBus } from "#/bus";
import { registerTrackingEventListeners } from "#/eventListener";
import { dataLayerPush } from "#/dataLayer";
import { mapPageData } from "#/payloadMapper";
import { getPageData } from "./pageData";
import { Flow } from "@mfe/tef-tracking-types";

/**
 * to be called on lib initial load on AEM page
 */
const pushPageView = () => {
    const pageData = getPageData();
    if (pageData.flowName) {
        window.sessionStorage.setItem(FLOW_KEY, pageData.flowName);
    } else {
        pageData.flowName = (window.sessionStorage.getItem(FLOW_KEY) as Flow) || "na";
    }

    dataLayerPush(
        {
            event: "page_view",
            page: mapPageData(pageData),
        },
        "tracking-lib",
    );
};

if (!window.tefTracking) {
    registerTrackingEventListeners();
    setupTrackingEventBus();

    // Page push is disabled for pi-15, cause the lib is not globally available yet
    //pushPageView();
}
