import { ViewCartEvent } from "@mfe/tef-tracking-types";
import { dataLayerPush } from "#/dataLayer";
import { mapViewCart } from "#/payloadMapper";
import { fetchCommerceData } from "#/trackingClient";
import { TrackingEventHandler } from "../../TrackingEventHandlerType";

export const handleViewCart: TrackingEventHandler<ViewCartEvent> = async (evt) => {
    return dataLayerPush(
        mapViewCart(evt.detail.trackingEvent, await fetchCommerceData(evt.detail.trackingEvent.offers)),
        evt.detail.trackingEvent.mfeName,
    );
};
