import { AddToCartEvent } from "@mfe/tef-tracking-types";
import { dataLayerPush } from "#/dataLayer";
import { mapAddToCart } from "#/payloadMapper";
import { fetchCommerceData } from "#/trackingClient";
import { TrackingEventHandler } from "../../TrackingEventHandlerType";

export const handleAddToCart: TrackingEventHandler<AddToCartEvent> = async (evt) => {
    return dataLayerPush(
        mapAddToCart(evt.detail.trackingEvent, await fetchCommerceData(evt.detail.trackingEvent.offers)),
        evt.detail.trackingEvent.mfeName,
    );
};
