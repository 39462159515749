import { RemoveFromCartEvent } from "@mfe/tef-tracking-types";
import { dataLayerPush } from "#/dataLayer";
import { mapRemoveFromCart } from "#/payloadMapper";
import { fetchCommerceData } from "#/trackingClient";
import { TrackingEventHandler } from "../../TrackingEventHandlerType";

export const handleRemoveFromCart: TrackingEventHandler<RemoveFromCartEvent> = async (evt) => {
    return dataLayerPush(
        mapRemoveFromCart(evt.detail.trackingEvent, await fetchCommerceData(evt.detail.trackingEvent.offers)),
        evt.detail.trackingEvent.mfeName,
    );
};
